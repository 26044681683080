<template>
  <div class="aboutUs">
    <div class="bgContainer">
      <img class="bgImage" src="@/assets/pageBg/about.jpg" />
    </div>
    <div class="contentContainer">
      <div class="introduce">
        <h1>公司介绍</h1>
        <div class="introduce_content">
          <p class="indentation">福州凝聚空间，简称“凝聚空间”，创立于2020年10月，位于马尾区儒江西路60号中建海峡商务广场B座14层，是由福州凝聚信息科技有限公司发起成立并运营的线。上线下一体化创新创业服务平台。凝聚空间主要服务于互联网 +、电子信息、文化创意、商业模式创新等领域创业团队和初创公司。致力于打造成科技创新创业服务载体，一期规划建设场地面积1200多平，舒适的定制化办公环境，整合了独立办公区、开放工作区、路演区和健身、读书区等个性化功能区。业务涵盖创业孵化、企业服务、产业整合、股权投资等多个领域。</p>
          <p>创新驱动发展4核心：科技、人才、服务、管理</p>
          <p>服务版块：通过对用户发展状况和变化需求的充分挖掘，把用户的需求与企业 服务提供优化融合。</p>
          <p>项目版块：增强创业项目孵化、培育生命力，共享产品（服务）价值。</p>
          <p>活动版块：让创业者能够实现资源共享,为自己的梦想创造更多的可能性。</p>
          <p>公司口号：凝聚梦想携手众创。</p>
          <p>人才理念：创新之道，唯在得人。得人之要，必广其途以储之。</p>
          <p>服务理念：有责任、有智慧、有温度。</p>
          <p>管理理念：以人为本，突破自我。</p>
        </div>
      </div>
      <div class="address">
        <div class="contact">
          <p>联系电话：0591-83972230</p>
          <p>邮箱：gm@fznjkj.com</p>
          <p>网址：www.fznjkj.com</p>
          <p>地址：福建省福州市马尾区儒江西路60号中建海峡商务广场B座F14</p>
        </div>
        <div id="map" ref="map"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  mounted() {
    this.map();
  },
  methods: {
    map() {
      var map = new BMap.Map("map");    // 创建Map实例
      var point = new BMap.Point(119.410022,26.025850);    
      map.centerAndZoom(point, 18);  // 初始化地图,设置中心点坐标和地图级别
      //添加地图类型控件
      map.addControl(new BMap.OverviewMapControl({isOpen:false,anchor:BMAP_ANCHOR_BOTTOM_RIGHT}));
      map.addControl(new BMap.NavigationControl());
      //添加比例尺控件
      map.addControl(new BMap.ScaleControl());
      map.setCurrentCity("福州");          // 设置地图显示的城市 此项是必须设置的
      map.enableScrollWheelZoom(true);
      var marker = new BMap.Marker(point);        // 创建标注
      marker.setTitle("福州市马尾区马尾镇儒江西路60号中建海峡商务广场B座14层");
      map.addOverlay(marker);                     // 将标注添加到地图中
      marker.setAnimation(Animation);     //开启鼠标滚轮缩放
    }
  }
}
</script>

<style scoped>
@import '../../commonScss/style.css';

.introduce .introduce_content {
  margin: 0 100px;
  font-size: 18px;
  color: #555;
}

.introduce_content .indentation {
  text-indent: 2em;
  line-height: 30px;
  margin-top: 0;
  margin-bottom: 25px;
}

.address {
  margin-top: 50px;
}

.address .contact {
  width: 1000px;
  margin: 0px auto;
  font-size: 18px;
  color: #555;
}

.address #map{
  width: 1000px;
  height: 500px;
  overflow: hidden;
  margin: 0px auto;
}

</style>

<style>
.BMap_cpyCtrl {
  display: none;
}

.anchorBL {
  display: none;
}
</style>